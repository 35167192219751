<template>
  <div class="wrapper">
    <div
        style="margin: 150px auto; background-color: #fff; width: 350px; height: 340px; padding: 20px; border-radius: 10px">
      <div style="margin: 20px 0; text-align: center; font-size: 24px"><b>注 册</b></div>
      <el-form :model="user" :rules="rules" ref="userForm">
        <el-form-item prop="email">
          <el-input placeholder="请输入邮箱" size="medium" prefix-icon="el-icon-user" v-model="user.email"></el-input>
        </el-form-item>
        <el-form-item prop="verificationCode">
          <el-input placeholder="请输入验证码" size="medium" prefix-icon="el-icon-chat-dot-round" v-model="user.verificationCode">
            <template slot="append">
              <el-button size="medium" type="link" style="color: blue" @click="sendVerificationCode" :disabled="countingDown">{{ countdownText }}</el-button>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input placeholder="请输入密码" size="medium" prefix-icon="el-icon-lock" show-password
                    v-model="user.password"></el-input>
        </el-form-item>
        <el-form-item prop="confirmPassword">
          <el-input placeholder="请确认密码" size="medium" prefix-icon="el-icon-lock" show-password
                    v-model="user.confirmPassword"></el-input>
        </el-form-item>
        <el-form-item style="margin: 5px 0; text-align: right">
          <el-button type="primary" size="small" autocomplete="off" @click="login">注册</el-button>
          <el-button type="warning" size="small" autocomplete="off" @click="$router.push('/login')">返回登录</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      user: {},
      countdown: 0,
      countingDown: false,
      rules: {
        email: [
          { required: true, message: '请输入邮箱', trigger: 'blur' },
          { type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] }
        ],
        verificationCode: [
          { required: true, message: '请输入验证码', trigger: 'blur' }
        ],
        username: [
          {required: true, message: '请输入用户名', trigger: 'blur'},
          {min: 3, max: 10, message: '长度在 3 到 5 个字符', trigger: 'blur'}
        ],
        password: [
          {required: true, message: '请输入密码', trigger: 'blur'},
          {min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur'},
          { pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{1,}$/, message: '密码必须包含大小写字母和数字', trigger: 'blur' }

        ],
        confirmPassword: [
          {required: true, message: '请输入密码', trigger: 'blur'},
          {min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur'},
          { pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{1,}$/, message: '密码必须包含大小写字母和数字', trigger: 'blur' }
        ],
      }
    }
  },
  computed: {
    countdownText() {
      return this.countdown > 0 ? `${this.countdown}秒后重发` : '发送验证码';
    }
  },
  methods: {

    sendVerificationCode() {
      if (!this.user.email) {
        this.$message.error('请先输入邮箱');
        return;
      }
      // Simulate sending verification code, here you would implement actual sending logic
      this.request.post("/user/verificationCode", this.user).then(res => {
        if (res.code === '200') {
          this.$message.success("验证码已发送")
        } else {
          this.$message.error(res.msg)
        }
      })
      this.countdown = 60;
      this.countingDown = true;
      const countdownInterval = setInterval(() => {
        if (this.countdown > 0) {
          this.countdown--;
        } else {
          clearInterval(countdownInterval);
          this.countingDown = false;
        }
      }, 1000);
    },
    login() {
      this.$refs['userForm'].validate((valid) => {
        if (valid) {  // 表单校验合法
          if (this.user.password !== this.user.confirmPassword) {
            this.$message.error("两次输入的密码不一致")
            return false
          }
          this.request.post("/user/register", this.user).then(res => {
            if (res.code === '200') {
              this.$message.success("注册成功")
            } else {
              this.$message.error(res.msg)
            }
          })
        }
      });
    }
  }
}
</script>

<style>
.wrapper {
  height: 100vh;
  background-image: linear-gradient(to bottom right, #4169E1, #87CEFA);
  overflow: hidden;
}
</style>
